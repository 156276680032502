<template>
  <figure class="image">
    <img
      :src="mImageSrc"
      :style="{ backgroundColor: chosenRandomColor }"
      :alt="itemName"
      loading="lazy"
      @error="handleError"
    />
  </figure>
</template>

<script>
export default {
  name: 'ImageViewer',

  props: {
    type: {
      type: String,
      default: ''
    },
    imageSrc: {
      type: String,
      default: ''
    },
    itemName: {
      type: String,
      required: true
    },
    itemId: {
      type: Number,
      default: 0
    },
    imageSize: {
      type: Number,
      default: 0
    },
    aspectRatio: {
      type: Number,
      default: 4 / 3
    }
  },

  data() {
    return {
      mImageSrc: '',
      chosenRandomColor: this.randomBgColor()
    }
  },

  watch: {
    imageSrc: {
      immediate: true,
      handler: function name(params) {
        this.mImageSrc = this.getImageSource()
      }
    }
  },

  methods: {
    getImageSource() {
      if (!this.imageSrc) { return this.generateAvatar() }
      let source = ''
      switch (this.type) {
        case 'location':
          source = this.$options.filters.getLocationImage(
            this.imageSrc,
            this.itemId,
            this.imageSize
          )
          break

        case 'event':
          source = this.$options.filters.getEventImage(
            this.imageSrc,
            this.imageSize
          )
          break

        case 'group':
          source = this.$options.filters.getGroupImage(
            this.imageSrc,
            this.itemId,
            this.imageSize
          )
          break

        case 'article':
          source = this.$options.filters.getArticleImage(
            this.imageSrc,
            this.itemId,
            this.imageSize
          )
          break
      }

      return source + '?' + performance.now()
    },

    handleError(el) {
      el.target.src = this.generateAvatar()
    },

    generateAvatar() {
      let text = this.itemName.toUpperCase()
      let width = this.imageSize !== 0 ? this.imageSize : 1280
      let height = width / this.aspectRatio

      var canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      // Get the drawing context
      var ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.rect(0, 0, width, height)
      ctx.closePath();
      ctx.fillStyle = this.chosenRandomColor
      ctx.fill();
      ctx.fillStyle = "white";
      ctx.font = "bold 26px Arial";
      ctx.textAlign = 'center';
      this.wrapText(ctx, text, width / 2, height / 2 + 13, width - 20, 36)
      return canvas.toDataURL();
    },

    randomBgColor() {
      const arrColors = ['#4C9B9B', '#0B757A', '#CFE4E5', '#78FFDA', '#95c11e', '#fec632', '#fec632', '#00baff', '#ff6600']
      return arrColors[Math.floor(Math.random() * arrColors.length)]
    },

    wrapText(context, text, x, y, maxWidth, lineHeight) {
      var words = text.split(' ');
      var line = '';

      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var metrics = context.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = words[n] + ' ';
          y += lineHeight;
        }
        else {
          line = testLine;
        }
      }
      context.fillText(line, x, y);
    }
  }
}
</script>

<style lang="sass" scoped>
.image
  position: relative
  img
    object-fit: cover
</style>