<template>
  <ui-component-modal
    :modalTitle="'Edit option image'"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOptionImage"
    :disableSaveButton="disableSaveButton"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="columns is-mobile is-multiline is-vcentered">
        <div class="column is-7">
          <ImageCropper
            ref="imageCropper"
            :aspectRatio="4/3"
            :guides="false"
            :viewMode="3"
            previewContainer=".imagePreview"
            @callbackImageChosen="val => { newImageChosen = val }"
            @errorCallbackInvalid="val => { hasImageError = val }"
            aspectRatioCssClass="is-4by3"
          />
        </div>
        <div class="column is-5">
          <div class="wrapper-previews">
            <div class="imagePreview has-background-grey-light"></div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { cleanSource } from '@/utils/objectHelper.js'
import optionProvider from '@/providers/option'
import uploadProvider from '@/providers/upload'

const ImageCropper = () => import(/* webpackChunkName: "modal-edit-option-images" */ '@/components/UI/Components/ImageCropper')

export default {
  components: {
    ImageCropper,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      optionImage: '',
      mOption: null,
      newImageChosen: false,
      hasImageError: false
    }
  },

  computed: {
    ...mapState('optionStore', ['option']),

    disableSaveButton() {
      return this.isSaving || !this.newImageChosen || this.hasImageError
    },
  },

  created() {
    this.mOption = cleanSource(this.option)
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    async saveOptionImage() {
      if (this.disableSaveButton) { return }
      this.isSaving = true
      try {
        const NEW_IMAGE = this.$refs.imageCropper.getCroppedCanvas()
        let responseImageUpload = await uploadProvider.methods.locationImage(this.option.Location.LocationId, NEW_IMAGE)

        if (responseImageUpload.status === 200) {
          let locationSaveResponse = await optionProvider.methods
            .saveLocationOptionImage(
              this.option.Id,
              this.option.Location.LocationId,
              responseImageUpload.data
            )

          this.isSavingSuccess = true

          this.mOption.Image = locationSaveResponse.data.Image
          Vue.set(this.mOption.Images, 0, locationSaveResponse.data)
          this.setOption(this.mOption)

          let t = setTimeout(() => {
            this.onClickCancel()
            clearTimeout(t)
          }, 2000)
        }
      } catch {
        this.isSaving = false
        this.isSavingError = true
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.wrapper-previews
  display: flex
  flex-grow: 1
  .imagePreview
    overflow: hidden
    aspect-ratio: 4/3
    &:nth-child(1)
      max-width: 80%
      width: 100%
</style>
